import { Region } from '@/enums/Region'

export const regionOptions = [
  {
    value: Region.Global,
    label: "Global",
  },
  {
    value: Region.GlobalUS,
    label: "Global US",
  },
  {
    value: Region.Dach,
    label: "DACH",
  },
] as const
